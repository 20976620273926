import { useContext, useEffect } from "react";
import DataContext from "../context/DataContext";
import { TypeAnimation } from "react-type-animation";
import SectionTitle from "../components/global/SectionTitle";
import HomeAboutSection from "../components/home/HomeAboutSection";
import bulls_eye from "../media/images/bulls eye.png";
import progress_img from "../media/images/progress.png";
import GlowingLink from "../components/global/GlowingLink";
import HomeServiceSection from "../components/home/HomeServiceSection";
import Aos from "aos";

// IMAGES
import png_bg_1 from '../media/images/topbg.png'
import grid_bg from '../media/images/Maspeqa big text.png'
import maspeqa_home from '../media/images/maspeqa_home.png'
import png_bg_2 from '../media/images/Layer_2.png'
import png_bg_3 from '../media/images/Layer_1.png'
import sod_bg from "../media/images/new/Frame 86.png"
import graphic_design_bg from "../media/images/new/Frame 87.png"
import ui_ux_bg from "../media/images/new/Frame 89.png"
import system_design from "../media/images/new/Frame 85.png"
import mobile_bg_small from "../media/images/new/mockup.png"

import bottom_anim_bg from "../media/images/home bottom animation/background.png"
import bottom_anim_text from "../media/images/home bottom animation/anim-text.png"
import bottom_anim_maspeqa_accent from "../media/images/home bottom animation/maspeqa-accent.png"
import bottom_anim_maspeqa_blue from "../media/images/home bottom animation/maspeqa-blue.png"
import bottom_anim_maspeqa_white from "../media/images/home bottom animation/maspeqa-white.png"


const Home = () => {

    useEffect(()=>{
        Aos.init()
    })

    let { navHeight, setCurrentPage, availableHeight, offsetY } = useContext(DataContext)

    useEffect(()=>{
        setCurrentPage('home')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    // BOTTOM ANIMATION
    
    const animation_images_ids = [
        'maspeqa-blue',
        'maspeqa-accent',
        'maspeqa-white',
        'anim-text',
        'bottom-anim-bg'
    ]
    function animateFn() {
        animation_images_ids.forEach((animationImageId)=>{
            document.getElementById(animationImageId).classList.remove(animationImageId)
            document.getElementById(animationImageId).classList.add(`${animationImageId}-1`)
        })
    }
    function revertAnimateFn() {
        animation_images_ids.forEach((animationImageId)=>{
            document.getElementById(animationImageId).classList.add(animationImageId)
            document.getElementById(animationImageId).classList.remove(`${animationImageId}-1`)
        })
    }

    return ( 
        <div>
            <div className={`relative`} style={{height:`${availableHeight}px`}}>
                <img src={png_bg_1} alt="Maspeqa Home Page Background" className={`absolute right-[-30%] md:right-[-15%] w-full md:w-8/12 bottom-0 h-screen -z-50 rotate-90 opacity-40`} style={{top:`-${navHeight}px`, transform:`translateX(${offsetY*0.5}px)`}} />
                <img src={png_bg_1} alt="Maspeqa Home Page Background" className={`absolute left-[-30%] md:left-[-15%] w-full md:w-8/12 bottom-0 h-screen -z-50 rotate-90 opacity-40`} style={{top:`-${navHeight}px`, transform:`translateX(${offsetY*-0.5}px) rotateY(180deg)`}} />
                <div className="absolute top-1/2 left-1/2 scale-150 md:scale-100 -translate-y-1/2 -translate-x-1/2 -z-[100]">
                    <img src={grid_bg} alt="Maspeqa Home Page Background" className={` scale-[2] w-full spotlight-image`} />
                </div>
                <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full flex flex-col text-center items-center`}>
                    <h2 style={{marginTop:`-${navHeight}px`}}>
                        <span className="text-lg">Welcome to </span>
                    </h2>
                    <div data-aos='zoom-in' data-aos-duration='1500'>
                        <img src={maspeqa_home} alt="Maspeqa big text logo" className={` scale-75`}/>
                    </div>
                    <br />
                    <div className=" text-3xl md:text-6xl font-extrabold">
                        We Are Here To
                        <TypeAnimation
                            className="text-accent"
                            sequence={[
                                500,
                                ' Collaborate',
                                1000,
                                ' Design',
                                1000,
                                ' Develop',
                                1000,
                                ' Analyze',
                                500,
                            ]}
                            speed={5}
                            repeat={Infinity}
                        />
                    </div>
                </div>
            </div>

            <div className="mt-2 relative lg:mb-64">
                <img src={png_bg_2} alt="Maspeqa Home Page Background" className={`absolute md:-ml-[300px] lg:-ml-[10%] top-[80%] left-0 w-[350px] md:w-[800px] -z-50`} style={{transform:`translateY(-${offsetY*0.3}px)`}}/>
                <SectionTitle id='homepage-about-us' text='About Us'/>
                <div className="mt-10" >
                    <HomeAboutSection
                        styles='p-5 lg:p-12 lg:pb-32 lg:pr-[25%] lg:w-10/12'
                        heading='OUR COMPANY'
                        text=
                            ' We are a tech company, we specialize in crafting intelligent solutions that transform your vision into functioning apps and works of art. We are passionate about bringing your ideas to life, regardless of their complexity, through our flexible and adaptable approach <br />Maspeqa, born in Rwanda in 2020, is a collective of passionate minds driven by innovation<br />ur team of experts leverages their expertise to ensure every project exceeds expectations. We collaborate closely with you to turn your ideas into reality'
                        child={1}
                    />
                    <div className="md:flex mt-5 lg:mt-0 w-full lg:ml-[40%] md:justify-between lg:justify-end lg:-translate-x-[40%]">
                        <HomeAboutSection
                            styles='p-10 md:w-5/12 lg:w-4/12 lg:pb-24  lg:-mt-[6%] lg:mr-[5%]'
                            heading='OUR APPROACH'
                            imgPath={progress_img}
                            imgAlt="Maspeqa Aproach"
                            text=
                                ' Maspeqa pushes boundaries. We leverage cutting-edge AI and Machine Learning to craft intelligent, evolving solutions. Through deep collaboration, we tackle unique client challenges and deliver tailored, effective results, keeping you informed throughout the process'
                            child={2}
                        />
                        <HomeAboutSection
                            styles='p-10 md:w-5/12 lg:w-4/12 mt-5 sm:mt-0 lg:-mt-[20%]'
                            heading='OUR VISION'
                            imgPath={bulls_eye}
                            imgAlt="Maspeqa Vision"
                            text=
                                ' At Maspeqa, we dream big. Our vision is not only to become a leader in the tech industry, but also to revolutionize the way tech problems are solved <br /> We believe that by harnessing the power of technology and innovation, we can create a positive impact on the world, empowering individuals and organizations to thrive <br />Through our unwavering dedication and collaborative spirit, we strive to be a force for positive change, leaving a lasting legacy in the tech landscape'
                            child={3}
                        />
                    </div>
                </div>
            </div>
            <div className={`mt-[10%] relative`}>
                <img src={png_bg_3} alt="Maspeqa Home Page Background" className={`absolute md:-left-[20%] lg:-left-[30%] top-[100%] left-0 w-[250px] md:w-[800px] h-fit -z-50`}  style={{transform:`translate(${offsetY*0.5}px,-${offsetY*0.5}px)`}}/>
                <SectionTitle id='home-services'>
                    Check Our <span className='text-accent custom-hide'>Services</span>
                </SectionTitle>
                <p className="lg:w-1/2 text-lg text-gray-300 my-10">We offer flexible solutions to meet your unique needs, across the services listed below.Experience seamless execution of projects, regardless of complexity, with our comprehensive services.</p>
                <GlowingLink to='/services' text='Explore all' styles='w-fit mb-10 px-6 py-1' />
                <div className="sm:flex sm:flex-wrap sm:justify-around lg:justify-normal lg:grid lg:grid-cols-4 lg:grid-rows-4 lg:gap-4">
                    <HomeServiceSection styles=' lg:col-span-1 lg:row-span-1' data_aos='500'>
                        <img src={sod_bg} alt="Maspeqa services - Website development" className={`absolute w-full right-0 left-0 top-0 bottom-0 h-full `} />
                        <div className="absolute top-0 right-0 bottom-0 left-0"></div>
                        {/* <h3 className="text-right absolute top-[5%] right-[5%]">
                            WEBSITE DEVELOPMENT
                        </h3> */}
                    </HomeServiceSection>
                    <HomeServiceSection styles='bg-[#DB1B24] lg:col-span-1 lg:row-span-2 relative' data_aos='3500'>
                        <img src={mobile_bg_small} alt="Maspeqa services - Mobile development" className={`absolute top-[50%] md:bottom-0 right-0 `} />
                        <h3 className=" text-left absolute top-2 left-2">
                            MOBILE DEVELOPMENT
                        </h3>
                    </HomeServiceSection>
                    <HomeServiceSection styles='bg-blue-300 lg:col-span-1 lg:row-span-1' data_aos='10500'>
                        <img src={system_design} alt="Maspeqa services - System design" className={`absolute top-0 left-0 w-full`} />
                        {/* <h3 className=" text-left absolute bottom-[2%] left-[5%]">
                            SYSTEM DESIGN
                        </h3> */}
                        
                    </HomeServiceSection>
                    <HomeServiceSection styles=' hidden lg:block bg-transparent lg:col-span-1 lg:row-span-1'>
                        {/* <img src="" alt="" /> */}
                        <h3 className=" text-left">
                        </h3>
                    </HomeServiceSection>
                    <HomeServiceSection styles='bg-blue-700 lg:col-span-1 lg:row-span-1' data_aos='1500'>
                        <img src={graphic_design_bg} alt="Maspeqa services - Graphic design" className={`absolute top-0 left-0 bottom-0 right-0`} />
                        {/* <h3 className=" text-left absolute top-[5%] left-[5%]">
                            DIGITAL MARKETING
                        </h3> */}
                        
                    </HomeServiceSection>
                    <HomeServiceSection styles='bg-blue-800 lg:col-span-2 lg:row-span-1'data_aos='5500'>
                        <img src={ui_ux_bg} alt="Maspeqa services - UI/UX" className={`absolute right-[5%] top-0 bottom-0 left-0`} />
                        {/* <div className="absolute top-0 left-0 bottom-0 right-[70%] bg-indigo-500"></div> */}
                        {/* <h3 className=" text-left absolute left-[5%] top-1/2 -translate-y-1/2 custom-hide">
                            UI/UX DESIGN
                        </h3> */}
                    </HomeServiceSection>
                </div>
            </div>

            <div className={`flex flex-col lg:flex-row align-center content-center lg:justify-between mt-[350px] sm:mt-[730px] lg:mt-0`}>
                <div className="animation font-bold text-3xl w-full lg:w-1/2 relative overflow-hiden h-full">
                    <div className="absolute -top-[180px] left-0 right-0 bottom-0 z-30 bg-yellw-200" onMouseOver={animateFn} onMouseLeave={revertAnimateFn}></div>
                    <div className="base w-full md:w-11/12 relative left-[50%] -translate-[50%] py-5 lg:py-0 lg:w-[500px] -z-50">
                        <div>
                            <img src={bottom_anim_maspeqa_blue} className="anim-image maspeqa-blue" id="maspeqa-blue" alt="Maspeqa home animation - logo 1" />
                            <img src={bottom_anim_maspeqa_accent} className="anim-image maspeqa-accent" id="maspeqa-accent" alt="Maspeqa home animation - logo 2" />
                            <img src={bottom_anim_maspeqa_white} className="anim-image maspeqa-white" id="maspeqa-white" alt="Maspeqa home animation - logo 3" />
                        </div>
                        <div>
                            <img src={bottom_anim_bg} className="bottom-anim-bg" id="bottom-anim-bg" alt="Maspeqa animation - logo 4" />
                            <img src={bottom_anim_text} className="anim-image anim-text" id="anim-text" alt="Maspeqa animation - Creativity_Passion_Hardwork" />
                        </div>
                    </div>
                </div>
                <div className="lg:w-5/12 lg:text-left h-full align-bottom -mt-[10%]" data-aos='zoom-in-left' data-aos-duraction='2000'>
                    <SectionTitle id='home-portfolio'direction={1} >What we <span className="text-accent"> worked on</span></SectionTitle>
                    <p className="lg:text-left text-gray-300 my-7">
                    Ready to collaborate and achieve your goals? Explore our impactful portfolio with projects that our team worked on in the past.
                    </p>
                    <div className="sm:flex sm:justify-between">
                        <GlowingLink to='/portfolio' text='Browse The Portfolio' styles='w-52 py-2' />
                        <GlowingLink to='/image-gallery' text='Check The Gallery' styles='w-52 py-2' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;